<template>
  <win-block>
    <template #win-title-left>Участники проекта</template>

    <v-card flat min-height="450px">

      <v-data-table
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2 softvision"
                   @click="form_user.item_edit=null; form_user.show=true">
              Добавить
            </v-btn>
            <v-dialog v-if="item_select" v-model="dialog_delete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Удалить {{ item_select.user.username }} ?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog_delete=false">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="itemDelete">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2"
                  @click="form_user.item_edit=item; form_user.show=true">
            mdi-pencil
          </v-icon>
          <v-icon small @click="dialog_delete=true;item_select=item ">
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="initialize"
          >
            Reset
          </v-btn>
        </template>

      </v-data-table>

    </v-card>


    <form-user :form="form_user" @saved="itemSaved($event)"/>


  </win-block>
</template>

<script>
export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
    "form-user": () => import('./project-table_users-form_user'),
  },
  data() {
    return {
      dialog_delete: false,
      item_select: null,
      form_user: {show: false, item_edit: {}},
      loading: true,
      headers: [
        {text: 'Имя', value: 'user.username', align: 'center'},
        {text: 'Роль', value: 'role_text'},
        {text: 'Логин', value: 'user.last_login',},
        {text: '', value: 'actions', sortable: false},

      ],
      items: [],
      balance_global: 0,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.$axios.get(this.$url.core_project_users)
          .then(response => {
            this.items = response.data
            this.loading = false
          })
    },
    itemSaved(item) {
      if (this.form_user.item_edit) {
        // window.console.log('=== update item', item)
        let index = this.items.findIndex(obj => obj.id === item.id);
        this.items = Object.assign([], this.items, {[index]: item})
      }
      if (!this.form_user.item_edit) {
        this.items.push(item)
      }
    },
    itemDelete() {
      this.$axios.delete(this.$url.core_project_users + `${this.item_select.id}/`)
          .then(response => {
            response
            let index = this.items.findIndex(obj => obj.id === this.item_select.id);
            this.items.splice(index, 1);
            this.dialog_delete = false
          })
    }
    ,
  }
}
</script>

